<template>
  <f7-page class="product-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <ProductNavigationComponent title="" type="back" searchname="searchmerchantlist" />
    </template>

    <section class="options-container">
      <div class="container">
        <div class="categories">
          <f7-link>{{ $t.getTranslation("LBL_ALL_MERCHANTS") }} <font-awesome-icon :icon="['fas', 'chevron-down']" fixed-width /></f7-link>
        </div>
        <div class="actions">
          <!-- <f7-link panel-toggle="#rightPanel"><font-awesome-icon :icon="['far', 'filter']" fixed-width /></f7-link> -->
          <!-- <f7-link><font-awesome-icon :icon="['fas', 'th']" fixed-width /></f7-link> -->
        </div>
      </div>
    </section>

    <section class="merchant-list-container">
      <div class="container">
        <!-- <div v-for="(name, i) in ['Gold Vineyard', 'Adamantite Winery', 'Platinum Vineyard', 'Diamond Vineyard', 'Ruby Winery', 'Amethyst Winery', 'Silver Vineyard']" :key="'mc_' + i" class="merchant" @click="onViewMerchant">
          <div class="image">
            <img :src="require('@/assets/images/demo/merchants/' + ((i % 5) + 1) + '.jpg')" alt="Merchants Picture" />
          </div>
          <div class="content">
            <h3>{{ name }}</h3>
            <p>Lorem ipsum dolor sit amet conse ctetur lokasa, adipisicing elit...</p>

            <div class="sub">
              <span class="selections">99+ {{ $t.getTranslation("LBL_SELECTIONS") }}</span>
              <span class="year">1999</span>
            </div>
          </div>
        </div> -->

        <div v-for="(merchant, i) in list" :key="'mc_' + i" class="merchant" @click="onViewMerchant(merchant)">
          <div class="image" style="height: 90px; width: 90px; box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%); border-radius: 15px; display: flex; align-items: center; justify-content: center; align-content: center; overflow: hidden; padding: 5px;">
            <img style="height: auto; width: 100%;" :src="$h.getImage(merchant?.profileImage, 'MERCHANT', null, true)" :alt="merchant.Name" loading="lazy"/>
          </div>
          <div class="content">
            <h3>{{ $h.formatTrimString(merchant.Name, 200) }}</h3>
            <!-- <p>{{ $h.formatTrimString(merchant.Description, 300) }}</p> -->

            <div class="sub">
              <span class="selections">{{ $t.getTranslation("LBL_TOTAL_PRODUCT_COUNT") }} {{ merchant.TotalProductCount }}</span>
              <span class="year"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, inject, watch, defineAsyncComponent } from "vue";
import { configs } from "@/utils/configs.js";
import { helpers } from "@/utils/helpers.js";
import { get } from "@/utils/axios";
import { useStore } from "@/store";


// import ProductNavigationComponent from "@/components/navigations/ProductNavigationComponent.vue";

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"));

export default defineComponent({
  name: "MerchantListPage",
  components: { ProductNavigationComponent },
  props: { f7router: Object },
  setup(props) {
    const store = useStore();

    const $t = inject("$translation");

    const list = ref([]);
    const showPreloader = ref(false);

    let page = 1;
    let size = 30;
    let lastPage = 1;

    const onViewMerchant = (Merchant) => {
      props.f7router.navigate({ name: "merchantViewPage", params: { code: Merchant.UrlShortCode || Merchant.MerchantKey } });
    };

    const getData = async () => {
      let ret = await get(`/merchant/list?page=${page}&size=${size}`, {
        page: page,
        size: size,
        LanguageCode: $t.getLanguage(),
      });

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          item.profileImage = null;

          let profileImage = _.filter(item.MerchantImages, function (item, index) {
            return item.FieldName == "ProfileBannerImage";
          });

          if (profileImage.length > 0) {
            item.profileImage = profileImage[0].FileName;
          }

          list.value.push(item);
        }
        lastPage = ret?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage >= page ? getData() : false;
    };

    onMounted(() => {
      getData();
    });

    return { onViewMerchant, list, showPreloader, store };
  },
});
</script>
